import Back__TagsSelectController from "./tags_select_controller"
// Connects to data-controller="back--tags-select-product"
export default class extends Back__TagsSelectController {
  debugDisplayLinkedEntities() {
    console.log('Product', this.linkedEntityIds())
  }

  linkedEntityIds() {
    return [this.entityId];
  }

  propagateAppendVal(id) {
    $.each($('.tags_select_component').not($(this.element)), (i, component) => {
      $(component).get(0).controller.appendVal(id)
    })
  }

  propagateRemoveVal(id) {
    $.each($('.tags_select_component').not($(this.element)), (i, component) => {
      $(component).get(0).controller.removeVal(id)
    })
  }
}
