import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--generic-api-export"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
  }

  exportXlsx() {
    $('.menu_export_xlsx').remove()

    $('#loading_app').removeClass('d-flex')
    initModal()
    $('.modal-header').hide()
    $('.modal-body').html('Votre fichier est en cours de préparation. Cela peut prendre un peu de temps. Vous pourrez fermer cette fenêtre à la fin du téléchargement')
    $('.modal-footer').html(`
      <i class="fas fa-spinner fa-spin" style='font-size: 1.5em; color: #E43B57;'></i>
    `)

    $('.modal-footer').show()
    $('#modal_rails_5').modal({
      show: true,
      backdrop: 'static',
      keyboard: false
    })
    setTimeout(() => {
      $('.modal-footer').html(`<button class='btn btn-outline-primary' onClick="$('#modal_rails_5').modal('hide')">J'ai bien reçu mon fichier</button>`)
    }, 5000)    
    let data = $(this.element).data()
    let url = `${data.endpoint}/xlsx/${data.type}/shopgroup/${data.shopGroupId}`
    $.each(data.shops, (i, shop_id) => {
      url += i == 0 ? '?' : '&'
      url += `ShopId=${shop_id}`
    })
    
    window.location = url
  }
}
