import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--supplier-purchase-order"
export default class extends Controller {
  static targets = ['IngredientSelect', 'ProductStockSelect', 'stockableContainer']

  connect() {
    this.element['controller'] = this
    $(this.element).validate()

    $.ajax({
      url: `/back/supplier_purchase_orders/fetch_form_datas?purchasable_id=${$('.supplier_purchase_order_form').data().purchasableid}&purchasable_type=${$('.supplier_purchase_order_form').data().purchasabletype}`,
      method: 'get',
      dataType: "json",
      success: (response) => {
        this.stockable_data = response.data
        this.populate()
        if(typeof(this.product_names) == "undefined" && $(this.element).get(0).id != 'supplier_purchase_order_ask_import') {
          //location.reload()
        }
        this.loadStockable()
        this.calc_total()
      }
    })
  }

  populate() {
    for(const stockable of ['Ingredient', 'ProductStock']) {
      if(Object.keys(this.stockable_data[stockable]).length) {
        $(this.stockableContainerTarget).append(`
          <div class="d-flex mb-3 supplier_purchase_orders_stockable_container">
            <div class="form-label-group select optional supplier_purchase_order_product_stock_id">
              <select class="form-control select optional select_prevent_remove" data-back--supplier-purchase-order-target='${stockable}Select'>
                ${this.populateStockable(stockable)}
              </select>
            </div>
            <p class="btn btn-primary" data-stockable="${stockable}" data-action="click->back--supplier-purchase-order#addStockable">Ajouter ${stockable == 'Ingredient' ? 'cet ingrédient' : 'ce produit'}</p>
          </div>
        `)
      }
    }
    initElementSelect2($(this.stockableContainerTarget).find('select'))
  }

  populateStockable(stockable_key) {
    let html = ''
    for(const stockable in this.stockable_data[stockable_key]) {
      if(stockable_key == 'Ingredient' || this.stockable_data[stockable_key][stockable].not_archived) {
        html += `<option value='${stockable}'>${this.stockable_data[stockable_key][stockable].name}</option>`
      }
      
    }
    return html
  }

  addStockable(e) {
    let element = $(e.currentTarget)
    element.hide()
    $('.supplier_purchase_order_cocoon_add_line').click()
    let stockable = $(e.currentTarget).data().stockable
    let select = $(this[`${stockable}SelectTarget`])

    let params = new URLSearchParams({
      suppliable_id: $('#supplier_purchase_order_suppliable_id').val(),
      suppliable_type: $('#supplier_purchase_order_suppliable_type').val(),
      purchasable_type: $('#supplier_purchase_order_purchasable_type').val(),
      purchasable_id: $('#supplier_purchase_order_purchasable_id').val(),
      stockable_type: stockable,
      stockable_id: select.val()
    }).toString()

    $.ajax({
      url: `/back/supplier_purchase_orders/stockable_informations?${params}`,
      method: 'get',
      dataType: "json",

    }).done(data => {
      $('.supplier_purchase_order_line_stockable_id').eq(-1).val(select.val())
      $('.supplier_purchase_order_line_stockable_type').eq(-1).val(stockable)
      $('.supplier_purchase_order_line_name').eq(-1).text(select.find(':selected').text())
      $('.supplier_purchase_order_supplier_purchase_order_lines_quantity_needed .input-group-text').eq(-1).text(this.stockable_data[stockable][select.val()].referent_unit)
      $('.supplier_purchase_order_supplier_purchase_order_lines_unit_price_ht input').eq(-1).val(data.price)
      $('.supplier_purchase_order_line_remaining_permanent').eq(-1).text(data.remaining_permanent)
      $('.supplier_purchase_order_supplier_purchase_order_lines_previous_buying_price_ht input').eq(-1).val(data.price)
      $('.supplier_purchase_order_line_fields').get(-1).controller.calcProgression()
      if(data.remaining_permanent_warehouse) {
        $('.supplier_purchase_order_line_remaining_permanent_warehouse').eq(-1).text(data.remaining_permanent_warehouse)
      }
      select.find(':selected').prop('disabled', true)
      element.show()
      this.toggleStockableButton(select)
      
    })

  }

  toggleStockableButton(select) {
    if(select.length == 0) { return }
    select.val(select.find('option').not(':disabled').eq(0).val())
    select.parents('.supplier_purchase_orders_stockable_container').toggleClass('d-flex', select.find('option').not(':disabled').length > 0)

    updateSelect2(select)
    select.trigger('change')
  }

  loadStockable() {
    $.each($('.supplier_purchase_order_line_fields'), (index, element) => {
      let stockable_id = $(element).find('.supplier_purchase_order_line_stockable_id').val()
      let stockable_type = $(element).find('.supplier_purchase_order_line_stockable_type').val()
      $(element).find('.supplier_purchase_order_supplier_purchase_order_lines_quantity_needed .input-group-text').eq(-1).text(this.stockable_data[stockable_type][stockable_id].referent_unit)
      $(element).find('.supplier_purchase_order_line_name').text(this.stockable_data[stockable_type][stockable_id].name)
      $(this[`${stockable_type}SelectTarget`]).find(`option[value="${stockable_id}"]`).prop('disabled', true)
    })
    for(const stockable of ['Ingredient', 'ProductStock']) {
      if(Object.keys(this.stockable_data[stockable]).length) {
        this.toggleStockableButton($(this[`${stockable}SelectTarget`]))
      }
    }
  }

  removeStockable(e) {
    let nested = $(e.currentTarget).parents('.supplier_purchase_order_line_fields')
    let select = $(this[`${nested.find('.supplier_purchase_order_line_stockable_type').val()}SelectTarget`])

    select.find(`option[value="${nested.find('.supplier_purchase_order_line_stockable_id').val()}"]`).prop('disabled', false)
    this.toggleStockableButton(select)

    nested.find('.remove_supplier_purchase_order_line_cocoon').click()
    setTimeout(() => { this.calc_total() }, 200)
  }


  calc_total() {
    this.total = 0
    $.each($('.supplier_purchase_order_supplier_purchase_order_lines_buying_price_ht:visible'), (index, element) => {
      this.total += parseFloat($(element).find('input').val())
    })
    $('.supplier_purchase_order_total').text(this.total.toFixed(2))
    $('#supplier_purchase_order_totalHT').val(this.total.toFixed(2))
    if($('#franco_de_port_remaining_form').length) {
      let franco = parseFloat($('#franco_de_port_remaining_form').data().franco) - this.total
      if(franco > 0) {
        $('#franco_de_port_remaining_form').text(franco)
      } else {
        $('#franco_de_port_remaining_form').text(0)
      }
    }
  }
}
