import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--income"
export default class extends Controller {
  connect() {
    $(this.element).find('.income_numero').rules('add', {required: true})
    $.each($(this.element).find('.toporder_pay_sub_fields_container .mandatory_toporder_pay'), (i, e) => {
      $(e).toggleClass('optional', !$('.toporder_pay_input').prop('checked'))
      $(e).rules('add', {superior_to_zero: () => {return $('.toporder_pay_input').prop('checked')}})
    })

    this.fixedFeesChanged()
    this.element['controller'] = this
    this.valrhonaCustomerChanged()
    $('.income_installation_date').datepicker({language: 'fr', autoclose: true}).on('hide', (e) => {
      setTimeout(() => { this.calc() }, 200)
    })
    this.client_type = $(".new_client_form").length ? "new_customer" : "prospect"
    this.calc()
    this.handle_locam()

    $(this.element).find('select').each(function(){
      if($(this).parents('.customer_equipment_fields').length){
      }else{
        $(this).on('select2:select',function(){this.dispatchEvent(new Event('change',{bubbles:true}))})
      }
    })
  
  }

  fixedFeesChanged() {
    $(this.element).find('.income_toporder_fees_input .input-group-text').text(
      $(this.element).find('.income_fixed_fees_checkbox').prop('checked') ? "€" : "%"
    )
  }

  toporderPayChanged(e) {
    $(this.element).find('.toporder_pay_sub_fields_container .mandatory_toporder_pay').toggleClass('optional', !$(e.currentTarget).prop('checked'))
    $(this.element).find('.toporder_pay_sub_fields_container').toggleClass('d-none', !$(e.currentTarget).prop('checked'))
  }
  
  toggle_recap_equipments(e) {
    if($(this.element).find('.income_recap_customer_equipments:visible').length == 0) {
      $(this.element).find('.income_recap_customer_equipments').show()
      $(e.currentTarget).text('Cacher')
    } else {
      $(this.element).find('.income_recap_customer_equipments').hide()
      $(e.currentTarget).text('Voir')
    }
  }

  next_action(e) {
    $("#new_customer_next_action").val($(e.currentTarget).data().url)
    $(".new_client_form").submit()
  }

  signature_date_changed() {
    $(this.element).find('.incomes_customer_equipments_equipment_stock_id_container').toggle(true)
  }

  add_income_option() {
    $(this.element).parents('.entity_fields').find('.add_income_cocoon').click()
    let add_income = $('.income_fields').eq(-1)
    let income_id = $(this.element).data().id
    let temp_id = add_income.get(0).id.split('_')[2]
    $(`#income_nav_item_${temp_id}`).addClass(`income_option_tab_${income_id}`)
    $(`#income_nav_item_${temp_id}`).removeClass('income_nav_item')
    $(`#income_nav_item_${temp_id}`).addClass('income_nav_option_item')
    add_income.find('.new_customer_entities_incomes_engagement_loyer').addClass('d-none')
    $(`#income_nav_item_${temp_id} a`).text(`AVENANT ${$(`.income_option_tab_${income_id}`).length}`)
    $(`#income_nav_item_${temp_id}`).insertAfter($(`#income_nav_item_${income_id}`))
    add_income.find('.income_id_option').val($(this.element).data().id)
    add_income.find('.income_end_engagement').val($(this.element).find('.income_end_engagement').val())
    add_income.find('.new_customer_entities_incomes_coeff_locam').removeClass('d-none')
  }


  add_equipment(name) {
    $(this.element).find('.add_customer_equipment').click()
    let id = $('.customer_equipment_equipment_id_select select').eq(0).find('option').filter((index, e) => {return $(e).text() == name}).get(0).value
    $(this.element).find('.customer_equipment_equipment_id_select select').eq(-1).val(id).trigger('change')
    let ele = $(this.element).find('.customer_equipment_fields').get(-1)
    setTimeout(() => {
      ele.customerEquipment.equipment_stock_id_changed()
    }, 150)
  }

  calc() {
    setTimeout(() => {
        this.locam_version = $(this.element).find('.local_serialize_container').data().locam[$(this.element).find('.local_serialize_container select').val()]
      if ($(this.element).find('.income_short_income').prop('checked') == true){
        if($(this.element).find('.income_short_income_status').text() == "Statut : Actif"){
          this.engagement_months = Math.trunc(moment().diff(moment($(this.element).find('.income_installation_date').val(), "DD/MM/YYYY"), 'months', true))
        }else{
          this.engagement_months = Math.trunc(moment($(this.element).find('.income_end_engagement').val(), "DD/MM/YYYY").diff(moment($(this.element).find('.income_installation_date').val(), "DD/MM/YYYY"), 'months', true))
        }
      }else if ($(this.element).find('.income_id_option').val().length == 0){
        this.engagement_months = $(this.element).find('.income_select_engagement').val()
      }else{
        if ($(this.element).find('.income_installation_date').val().length == 0){
          this.engagement_months = Math.trunc(moment($(this.element).find('.income_end_engagement').val(), "DD/MM/YYYY").diff(moment(), 'months', true))
        }else{
          this.engagement_months = Math.trunc(moment($(this.element).find('.income_end_engagement').val(), "DD/MM/YYYY").diff(moment($(this.element).find('.income_installation_date').val(), "DD/MM/YYYY"), 'months', true))
        }
      }
      if (this.engagement_months == 0){
        this.engagement_months = 1
      }else if(isNaN(this.engagement_months )){
        this.engagement_months = 1
      }
      $(this.element).find('.engagement_months').val(Math.round(this.engagement_months))
      this.calc_locam()
      this.calc_treasury_locam()
      this.calc_global_locam()
      this.calc_cost()
      this.calc_ca()
      this.calc_margin()
      this.display_locam()
      this.display_booster()
      this.margin_in_red()
    }, 150)

  }


  locamChange(e){
    this.handle_locam()
    this.calc()
  }

  shortIncome(e){
    if ($(e.currentTarget).prop('checked') == true){
      $('.short_income_hidden').addClass('d-none')
    }else{
      $('.short_income_hidden').removeClass('d-none')
    }
    this.calc()
  }

  valrhonaCustomerChanged(){
    $(this.element).find('.short_income_container').toggleClass('d-none', 
      $(this.element).find('.valrhona_free_input').prop('checked') ||
      $(this.element).find('.annual_invoice_container input').prop('checked')
    )

    this.checkAnnual()
  }

  checkAnnual() {
    if($(this.element).find('.valrhona_free_input').prop('checked') || $(this.element).find('.income_locam_change').prop('checked')) {
      $(this.element).find('.annual_invoice_container').addClass('d-none')
      $(this.element).find('.annual_invoice_container input').prop('checked', false)  
    } else {
      $(this.element).find('.annual_invoice_container').removeClass('d-none')    
    }
  }

  handle_locam(){
    let locam = $(this.element).find('.income_locam_change')
    console.log(locam)
    let parent = locam.parents('.income_fields').eq(0)
    if(!locam.prop('checked')){
      $(this.element).find('.valrhona_free_input').parents('.form-group').removeClass('d-none')
      parent.find(`.income_select_engagement option[value="12"]`).prop('disabled', false)
      parent.find(`.income_select_engagement option[value="24"]`).prop('disabled', false)
    }else{
      $(this.element).find('.valrhona_free_input').parents('.form-group').addClass('d-none')
      $(this.element).find('.valrhona_free_input').prop('checked', false)     
      if(["12", "24"].includes(parent.find('.income_select_engagement').val())){
        parent.find(`.income_select_engagement`).val("36")
      }
      parent.find(`.income_select_engagement option[value="12"]`).prop('disabled', true)
      parent.find(`.income_select_engagement option[value="24"]`).prop('disabled', true)
    }
    updateSelect2(parent.find('.income_select_engagement').eq(0))
    this.valrhonaCustomerChanged()
  }

  display_locam(){
      $(this.element).find('.locam').toggleClass('d-none', !$(this.element).find('.income_locam_change').prop('checked'))
      $(this.element).find('.no_locam').toggleClass('d-none', $(this.element).find('.income_locam_change').prop('checked'))
  }

  display_booster(){
    if($(this.element).find('.income_booster_checked').prop('checked') == true){
      $('.at_least_one_booster').removeClass('d-none')
      return false
    }else{
      $('.at_least_one_booster').addClass('d-none')
    }
  }

  margin_in_red(){
    if( parseInt($('.percentage_marque').text().split(" ")[0]) <50 ) {
      $(".red_margin").css("color","red")
    } else {
      $('.red_margin').css("color","")
    }
  }

  calc_cost() {
    let equipment_cost = 0
    let expenses = 0
    let human_cost = 0
    let mdm = 0
    let locam_fees = 0
    let gocardless = 0
    this.total_expenses = 0

    // Calcul frais de déplacement
    $(this.element).find(".customer_expense_fields").not('.deleting_field').each((index, e) => {
        expenses += parseFloat($(e).find(`.customer_expense_cost`).val().replace(',','.'))
        if(isNaN(expenses )){ expenses = 0}
    })

    // Calcul coût humain
    $(this.element).find(".customer_time_fields").not('.deleting_field').each((index, e) => {
      let cost = $('form').eq(0).data().incomeTimeCosts[$(e).find(`.income_time_cost`).val()]
      human_cost += parseFloat($(e).find(`.customer_time_time`).val().replace(',','.')* cost)
      if(isNaN(human_cost )){ human_cost = 0}
    })

    // Calcul frais de dossier si le revenu est locam
    if($(this.element).find('.income_locam_change').prop('checked') == true){
      if (this.locam_version.fees) {
        locam_fees = this.locam_version.fees
      }
    }

    // Calcul coût équipements
    $(this.element).find(".customer_equipment_fields").not('.deleting_field').each((index, e) => {
      equipment_cost += parseFloat($(e).find(".income_customer_equipment_cost").val().replace(',','.'))
      if(isNaN(equipment_cost )){ equipment_cost = 0}
    })

    // Calcul frais gocardless si le revenu n'est pas locam
    let rent = 0
    let percentage = 0
    if($(this.element).find('.income_locam_change').prop('checked') == false){
      if ((parseFloat($(this.element).find('.income_loyer').val())+parseFloat(this.sum_modules())) * 0.01 <= 2 ){
        rent = parseFloat($(this.element).find('.income_loyer').val()*this.engagement_months+this.sum_modules()*this.engagement_months)
        percentage = rent*0.01
      } else {
        percentage = 2 * parseFloat(this.engagement_months)
      }
      gocardless = 1.2 * percentage + 0.2*this.engagement_months
    }

    // Frais mdm
    mdm += this.locam_version.mdm * $(this.element).find(".new_customer_pricing_fields").not('.deleting_field').length * this.engagement_months

    // Calcul Total
    this.total_expenses += parseFloat(expenses) + parseFloat(human_cost) + parseFloat(locam_fees) + parseFloat(gocardless) + parseFloat(mdm) + parseFloat(equipment_cost)
    $(this.element).find('.income_total_expense').val(this.total_expenses*100/100)
    $(this.element).find('.human_time_cost').val(human_cost*100/100)
    $(this.element).find('.gocardless').val(Math.round(gocardless*100)/100)
    $(this.element).find('.mdm').val(Math.round(mdm*100)/100)
    $(this.element).find('.equipement_cost').val(Math.round(equipment_cost*100)/100)
    $(this.element).find('.locam_fees').val(Math.round(locam_fees*100)/100)
    $(this.element).find('.installation_expenses').val(Math.round(expenses*100)/100)

    // Affichage des calculs en front

    $(this.element).find('.income_expenses_HT').text(Math.round(expenses * 100) / 100 + " €")
    $(this.element).find('.income_human_cost_HT').text(Math.round(human_cost * 100) / 100 + " €")
    $(this.element).find('.income_equipment_cost_HT').text(Math.round(equipment_cost * 100) / 100 + " €")
    $(this.element).find('.income_locam_fees').text(Math.round(locam_fees * 100) / 100 + " €")
    $(this.element).find('.income_gocardless').text(Math.round(gocardless * 100) / 100 + " €")
    $(this.element).find('.income_mdm').text(Math.round(mdm * 100) / 100 + " €")
    $(this.element).find('.income_total_HT').text(Math.round(this.total_expenses * 100) / 100 + " €")
  }

  sum_modules() {
    return parseFloat($(this.element).find('.income_module').val()) + parseFloat($(this.element).find('.toporder_pay_module_float').val())
  }

  calc_ca() {

    let rent = 0
    let rent_locam = 0
    this.achat = 0
    this.formation = 0
    this.total_ca = 0

    // Calcul Achats directs et formations
    this.achat = parseFloat($(this.element).find('.income_achat').val())
    this.formation = parseFloat($(this.element).find('.income_formation').val())

    // Calcul loyer
    if($(this.element).find('.income_locam_change').prop('checked') == false){ // si revenu pas locam
      rent = parseFloat($(this.element).find('.income_loyer').val()*this.engagement_months+this.sum_modules()*this.engagement_months)
    }else{ // si loyer locam
      rent_locam = (1-this.locam_version.locam) * (parseFloat($(this.element).find('.income_loyer').val()*this.engagement_months))
    }

    // Calcul Total
      this.total_ca += parseFloat(rent) + parseFloat(rent_locam) + parseFloat(this.achat) + parseFloat(this.formation) + parseFloat($(this.element).find('.first_month_income_HT').text().split(" ")[0])
      $(this.element).find('.income_total_ca').val(Math.round(this.total_ca*100)/100)

    // Affichage des calculs en front

    if(rent == 0){
      $(this.element).find('.display_rent').addClass('d-none')
    }else{
      $(this.element).find('.display_rent').removeClass('d-none')
      $(this.element).find('.income_rent').text(Math.round(rent * 100) / 100 + " €")
    }

    if(rent_locam == 0){
      $(this.element).find('.display_rent_locam').addClass('d-none')
    }else{
      $(this.element).find('.display_rent_locam').removeClass('d-none')
      $(this.element).find('.income_rent_locam').text(Math.round(rent_locam * 100) / 100 + " €")
    }

    if(this.achat == 0){
      $(this.element).find('.display_achat').addClass('d-none')
    }else{
      $(this.element).find('.display_achat').removeClass('d-none')
      $(this.element).find('.income_achat').text(Math.round(this.achat * 100) / 100 + " €")
    }

    if (this.formation == 0){
      $(this.element).find('.display_formation').addClass('d-none')
    }else{
      $(this.element).find('.display_formation').removeClass('d-none')
      $(this.element).find('.income_formation').text(Math.round(this.formation * 100) / 100 + " €")
    }

    $(this.element).find('.income_ca').text(Math.round(this.total_ca * 100) / 100 + " €")
  }


  calc_locam(){
    let finance_rent = 0
    let finance_contract = 0
    let coeff = 1
    this.booster_cost = 0
    this.first_month_income = 0
    let engagement = 0
    if ($(this.element).find('.income_id_option').val().length == 0){
      engagement = parseFloat($(this.element).find('.income_select_engagement').val())
    }else{
      engagement = parseFloat($(this.element).parents('.entity_fields').find('.income_select_engagement').val())
    }
    if($(this.element).find('.income_locam_change').prop('checked') == true){
      // Calcul du loyer financé
      finance_rent = (parseFloat($(this.element).find('.income_loyer').val())) * this.locam_version.locam

      // Calcul du contrat financé
      finance_contract = (parseFloat($(this.element).find('.income_loyer').val()*this.engagement_months) * this.locam_version.locam)
      //calcul du coeff
      if ($(this.element).find('.income_id_option').val().length == 0 || $(this.element).find('.income_locam_coeff').val().length == 0){
        switch (engagement) {
          case 36:
            coeff = this.calc_coeff('first_engagement',this.element)
            break;
          case 48:
            coeff = this.calc_coeff('second_engagement',this.element)
            break;
          case 60:
            coeff = this.calc_coeff('third_engagement',this.element)
            break;
        }
      }else{
        coeff = $(this.element).find('.income_locam_coeff').val()
      }

      if($(this.element).find('.income_booster_checked').prop('checked') == true){
        this.booster_cost = (finance_rent/coeff)*$(this.element).find('.income_booster_coeff').val()
      }
      if(typeof coeff != "undefined"){
        let without_coeff = (parseFloat($(this.element).find('.income_loyer').val())) * this.locam_version.locam
        console.log('loyer ', $(this.element).find('.income_loyer').val())
        console.log('locam ', this.locam_version.locam)
        console.log('coeff', coeff)
        this.first_month_income = (without_coeff /coeff)
      }
    }

    // Affichage des calculs en front
    $(this.element).find('.finance_rent').text(Math.round(finance_rent * 100) / 100 + " €")
    $(this.element).find('.finance_contract').text(Math.round(finance_contract * 100) / 100 + " €")
    $(this.element).find('.montant_booster').text(Math.round(this.booster_cost * 100) / 100 + " €")
    $(this.element).find('.booster_TTC').text(Math.round(this.booster_cost*1.2 * 100) / 100 + " €")
    $(this.element).find('.booster_cost').val(Math.round(this.booster_cost*100)/100)
  }

  formula(coeff,fields){
    let rent = (parseFloat($(fields).find('.income_loyer').val())+parseFloat($(fields).find('.income_module').val()))*this.locam_version.locam
    return rent/coeff
  }

  calc_coeff(engagment,fields) {
    let value_one = 0
    let if_one = false
    let value_two = 0
    let  if_two = false
    let value_three = 0
    let if_three = false
    let value_four = 0
    let if_four = false
    for(var i=0; i<this.locam_version.ranges.length;i++){
      switch (i) {
        case 0:
          value_one = this.formula(this.locam_version.ranges[i][engagment],fields)
          if (this.locam_version.ranges[i].start_price<value_one && value_one<this.locam_version.ranges[i].end_price){
            if_one=true
          }
        case 1:
          value_two = this.formula(this.locam_version.ranges[i][engagment],fields)
          if (this.locam_version.ranges[i].start_price<value_two && value_two<this.locam_version.ranges[i].end_price){
            if_two=true
          }
        case 2:
          value_three = this.formula(this.locam_version.ranges[i][engagment],fields)
          if (this.locam_version.ranges[i].start_price<value_three && value_three<this.locam_version.ranges[i].end_price){
            if_three=true
          }
        case 3:
          value_four = this.formula(this.locam_version.ranges[i][engagment],fields)
          if (this.locam_version.ranges[i].start_price<value_four && value_four<this.locam_version.ranges[i].end_price){
            if_four=true
          }
      }
    }
    if(if_one){
      return this.locam_version.ranges[0][engagment]
    }
    else if(if_two){
      return this.locam_version.ranges[1][engagment]
    }
    else if(if_three){
      return this.locam_version.ranges[2][engagment]
    }
    else if(if_four){
      return this.locam_version.ranges[3][engagment]
    } else {
    }
  }

  calc_treasury_locam(){
    // Calcul du premier mois perçu sans booster
    this.first_month_without_booster_and_fees_income_HT = this.first_month_income-this.booster_cost-this.locam_version.fees

    // Affichage des calculs en front
    $(this.element).find('.first_month_income_HT').text(Math.round(this.first_month_income* 100) / 100 + " €")
    $(this.element).find('.first_month_locam_invoice').val(Math.round(this.first_month_income* 100) / 100)
    $(this.element).find('.first_month_without_booster_and_fees_income_HT').text(Math.round(this.first_month_without_booster_and_fees_income_HT* 100) / 100 + " €")
    $(this.element).find('.first_month_without_booster_and_fees_income_TTC').text(Math.round(this.first_month_without_booster_and_fees_income_HT*1.2* 100) / 100 + " €")
  }

  calc_global_locam(){
    this.ca_contract = 0
    this.ca_end = 0
    this.locam_loss = 0
    let locam_rent = 0

    // Calculs du ca du contrat et du loyer locam HT
    if($(this.element).find('.income_locam_change').prop('checked') == true){
      this.ca_contract = parseFloat($(this.element).find('.income_loyer').val()*this.engagement_months)
      locam_rent = parseFloat($(this.element).find('.income_loyer').val())
    }
    // Calculs ca total fin engagement locam et perte locam
    this.ca_end = this.first_month_income+(1-this.locam_version.locam)*this.ca_contract
    this.locam_loss = Math.round((this.ca_contract-this.ca_end)*100)/100

    console.log('locam rent', locam_rent)
    console.log('sum_modules', this.sum_modules())
    console.log('loyer', $(this.element).find('.income_loyer').val())
    console.log('locam ', this.locam_version.locam)
    // Affichage des calculs en front
    $(this.element).find('.ca_contract').text(Math.round(this.ca_contract * 100) / 100 + " €")
    $(this.element).find('.ca_end').text(Math.round(this.ca_end * 100) / 100 + " €")
    $(this.element).find('.locam_rent').text(Math.round((1-this.locam_version.locam)*locam_rent * 100) / 100 + " €")
    $(this.element).find('.locam_rent_ttc').text(Math.round(1.2 * (1-this.locam_version.locam)*locam_rent * 100) / 100 + " €")
    $(this.element).find('.total_reccurence_locam').text(Math.round((1-this.locam_version.locam)*this.ca_contract*100)/100 + " €")
    let division = (!this.ca_contract || this.ca_contract == 0) ? 1 : this.ca_contract
    $(this.element).find('.locam_loss_percentage').text(Math.round((1-this.ca_end/division) * 10000) / 100 + " %")
    $(this.element).find('.locam_loss').text(this.locam_loss+ " €")

  }

  calc_margin(){
    let margin = Math.round((this.total_ca - this.total_expenses)*100)/100
    // taux de marque
    let division = (this.total_ca && this.total_ca != 0) ? this.total_ca : 1
    let percentage_marque = Math.round(((this.total_ca - this.total_expenses)/division)*10000)/100
    // taux de marge
    division = (this.total_expenses && this.total_expenses != 0) ? this.total_expenses : 1
    let percentage_margin = Math.round(((this.total_ca - this.total_expenses)/division)*10000)/100
    // taux de marge fictive
    division = (!this.ca_contract || this.ca_contract == 0) ? 1 : this.ca_contract
    let fictive_margin = Math.round(((this.ca_contract + this.achat + this.formation - (this.total_expenses + (this.ca_contract - this.ca_end))) / division)*10000)/100
    

    // Affichage des calculs en front
    $(this.element).find('.fictive_margin').text(fictive_margin + " %")
    if($(this.element).find('.income_locam_change').prop('checked') == true){
      $(this.element).find('.fictive_margin').val(fictive_margin*100/100)  
    }
    else{
      $(this.element).find('.fictive_margin').val(0)  
    }

    $(this.element).find('.commercial_margin').text(margin + " €")
    $(this.element).find('.income_total_margin').val(margin*100/100)

    $(this.element).find('.percentage_marque').text(percentage_marque + " %")
    if(this.total_ca != 0){
      $(this.element).find('.percentage_marque').val(percentage_marque*100/100)  
    }
    else{
      $(this.element).find('.percentage_marque').val(0)  
    }

    $(this.element).find('.percentage_margin').text(percentage_margin + " %")
    $(this.element).find('.percentage_margin').val(percentage_margin*100/100)
  }
}
