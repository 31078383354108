import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';
// Connects to data-controller="utils--turbo-pagination"
export default class extends Controller {
  connect() {
    $(this.element).on('click', 'a', (e) => {
      e.preventDefault()
      this.urlParams = new URLSearchParams(window.location.search);
      this.url = window.location.pathname
      let page = new URLSearchParams($(e.currentTarget).get(0).href.split('?')[1]).get('page')
      this.urlParams.set('page', page)
      if(this.urlParams.toString().length > 0) { this.url += `?${this.urlParams.toString()}`}
      ajaxTurboStream(this.url, true)
    })
  }
}
